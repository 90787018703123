export const chunkArray = <T>(items: T[], chunkSize: number): T[][] => {

  const result = items.reduce((resultArray: T[][], item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
  return result;
}


/**
 * This function only works with arrays of primitive values at the moment.
 * @param previous The original array for for which you want to find missing elements
 * @param current The original which used as a comparison
 * @returns an array with the missing items from the previous array not pressent in the current array
 */
export const arrayDifference = (previous: any[], current: any[]) => {
  return previous.filter(x => !new Set(current).has(x));
}

/**
 * @deprecated Please use `isLadenArray`
 * @param arr
 * @returns
 */
export const arrayHasValues = (arr: any[]) => {
  return Array.isArray(arr) && arr.length;
}

export const isLadenArray = (val: any): boolean => {
  return Array.isArray(val) && val.length > 0;
}

export const getArrayMatchingValues = (array1: any[], array2: any[]): any[] => {
  return array1.filter(val => {
    return array2.indexOf(val) !== -1;
  });
}